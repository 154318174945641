import React from "react";

import { HeaderItem } from "./header-item/header-item";
import { headers } from "./table-header.constants";

export const TableHeader: React.FC = () => {
  return (
    <>
      {headers.map((column) => {
        return <HeaderItem key={column.key} column={column} />;
      })}
    </>
  );
};
