export const formatPrice = (value: string | number) => {
  const numberedValue = typeof value === "string" ? parseFloat(value) : value;

  // Format the value with a maximum of 9 decimal places
  const formattedValue = numberedValue.toFixed(9);

  // Strip unnecessary trailing zeros
  const strippedValue = parseFloat(formattedValue).toString();

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 9,
    useGrouping: false,
  }).format(Number(strippedValue));
};