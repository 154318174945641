import { CryptoDetails } from "types";
import { ColumnOrder } from "context";

export const sortData = (data: CryptoDetails[], order: ColumnOrder | null) => {
  if (!order) return data;
  if (order.value === "asc") {
    return data.sort((a, b) => {
      const firstColumn = a[order.key];
      const secondColumn = b[order.key];

      if (typeof firstColumn === "string" && typeof secondColumn === "string")
        return firstColumn.localeCompare(secondColumn);

      return firstColumn > secondColumn ? 1 : -1;
    });
  }

  if (order.value === "desc") {
    return data.sort((a, b) => {
      const firstColumn = a[order.key];
      const secondColumn = b[order.key];

      if (typeof firstColumn === "string" && typeof secondColumn === "string")
        return secondColumn.localeCompare(firstColumn);

      return firstColumn > secondColumn ? -1 : 1;
    });
  }

  return data;
};
