import React, { useContext } from "react";

import { orderIcons } from "./header-item.constants";
import { Column } from "../table-header.types";
import { TableContext } from "context";

import styles from "./header-item.module.scss";

interface Props {
  column: Column;
}

export const HeaderItem: React.FC<Props> = ({ column }) => {
  const { order, setOrder } = useContext(TableContext);

  const handleColumnClick = () => {
    if (!column.enableOrder) return;
    if (!order || order?.value === "desc")
      setOrder({ key: column.key, value: "asc" });
    if (order?.value === "asc") setOrder({ key: column.key, value: "desc" });
  };

  const showOrder = column.key === order?.key;

  return (
    <div
      className={`${styles.headerItem} ${column.enableOrder ? styles.clickable : ""}`}
      onClick={handleColumnClick}
    >
      {column.name}
      {showOrder ? orderIcons[order?.value] : null}
    </div>
  );
};
