import React, { useState } from "react";

import { Order } from "types/order.types";
import { CryptoDetails } from "types";

export type ColumnOrder = {
  key: keyof CryptoDetails;
  value: Order;
};

type TableContextType = {
  order: ColumnOrder | null;
  setOrder: React.Dispatch<React.SetStateAction<ColumnOrder | null>>;
};

const initialValue: TableContextType = {
  order: null,
  setOrder: () => null,
};

export const TableContext = React.createContext<TableContextType>(initialValue);

interface Props {
  children: React.ReactNode;
}

export const TableContextProvider: React.FC<Props> = ({ children }) => {
  const [order, setOrder] = useState<ColumnOrder | null>(null);

  return (
    <TableContext.Provider value={{ order, setOrder }}>
      {children}
    </TableContext.Provider>
  );
};
