import React from "react";

import { CryptoDetails } from "types";
import { TableRow } from "./table-row/table-row";

interface Props {
  data: CryptoDetails[];
}

export const TableContent: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data.map((crypto, index) => (
        <TableRow key={crypto.id} data={crypto} index={index} />
      ))}
    </>
  );
};
