import React from "react";

import { TableContextProvider } from "context";
import { CryptoTable } from "components/crypto-table/crypto-table";

export function App() {
  return (
    <TableContextProvider>
      <CryptoTable />
    </TableContextProvider>
  );
}
