import { Column } from "./table-header.types";

export const headers: Column[] = [
  { name: "Coin", key: "name", enableOrder: true },
  { name: "Entry Date", key: "entryDate" },
  { name: "Entry Price", key: "entryPrice", enableOrder: true },
  { name: "Last Price", key: "lastPrice", enableOrder: true },
  { name: "Overall gain/loss", key: "gainLoss", enableOrder: true },
  { name: "Buy Up To", key: "buyUpTo", enableOrder: true },
  { name: "Notes", key: "notes", enableOrder: true },
  { name: "Yield", key: "yield", enableOrder: true },
];
